import React, { useEffect, useRef, useState } from "react";
import CustomLink from "../components/CustomButton/CustomLink";
import { Icons } from "../components/icons";
import EduHeader from "../components/Header/EduHeader";
import OuterFooter from "../components/Footer/OuterFooter";
import feed_2 from "../assets/img/feed_2.png";
import upload_2 from "../assets/img/handWriting.png";
import analyze from "../assets/img/StudentOverview.png";
import { initGA, logPageView, logEvent } from "../components/Analytics";
const EducationPage = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef();

  useEffect(() => {
    // Initialize Google Analytics and log the page view
    initGA();
    logPageView();

    // Helper function to avoid duplicate tags
    const checkAndAppendTag = (tagName, attributes) => {
      const existingTag = document.querySelector(
        `${tagName}[${Object.keys(attributes)[0]}="${
          Object.values(attributes)[0]
        }"]`
      );
      if (existingTag) {
        existingTag.remove();
      }

      // Create a new tag
      const tag = document.createElement(tagName);
      Object.entries(attributes).forEach(([key, value]) => {
        tag.setAttribute(key, value);
      });
      document.head.appendChild(tag);
    };

    // Update Title
    document.title =
      "AI-Powered Exam Grading and Student Performance Analysis | Chanakya AI";

    // Meta Description
    checkAndAppendTag("meta", {
      name: "description",
      content:
        "Transform education with Chanakya AI's automated exam grading and student performance analysis tools. Reduce grading time, provide insightful feedback, and track academic progress effortlessly.",
    });

    // Meta Keywords
    checkAndAppendTag("meta", {
      name: "keywords",
      content:
        "AI paper grading, student performance analysis, automated grading system, SWOT analysis in education, AI in education, exam analysis reports, Chanakya AI",
    });

    // Canonical URL
    checkAndAppendTag("link", {
      rel: "canonical",
      href: "https://aichanakya.in/edu",
    });

    // Open Graph Tags
    checkAndAppendTag("meta", {
      property: "og:title",
      content:
        "AI-Powered Exam Grading and Student Performance Analysis | Chanakya AI",
    });
    checkAndAppendTag("meta", {
      property: "og:description",
      content:
        "Accelerate grading and gain valuable insights into student performance with Chanakya AI's automated tools. Save time and focus on what matters most—education.",
    });
    checkAndAppendTag("meta", {
      property: "og:image",
      content: "https://aichanakya.in/edu/analyze.jpg",
    });
    checkAndAppendTag("meta", {
      property: "og:url",
      content: "https://aichanakya.in/edu",
    });
    checkAndAppendTag("meta", { property: "og:type", content: "website" });

    // Twitter Card Tags
    checkAndAppendTag("meta", {
      name: "twitter:card",
      content: "summary_large_image",
    });
    checkAndAppendTag("meta", {
      name: "twitter:title",
      content:
        "AI-Powered Exam Grading and Student Performance Analysis | Chanakya AI",
    });
    checkAndAppendTag("meta", {
      name: "twitter:description",
      content:
        "Automate exam grading and analyze student performance with ease. Discover faster, smarter, and more insightful ways to assess student learning.",
    });

    // Favicon
    checkAndAppendTag("link", {
      rel: "icon",
      href: "https://aichanakya.in/favicon.ico",
    });

    // Robots Meta
    checkAndAppendTag("meta", { name: "robots", content: "index, follow" });
  }, []);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  const toggleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  };
  const howItWorksData = [
    {
      id: 0,
      icon: <Icons.BookOpen size={24} className="icon" />,
      title: "1. Feed",
      text: "Upload the test question paper and model answers into our secure platform.",
      images: [{ img: feed_2 }],
      tooltip_text: "Questions and Marking Scheme",
    },
    {
      id: 1,
      icon: <Icons.Upload size={24} className="icon" />,
      title: "2. Upload",
      text: "Submit answer sheet photos through the Chanakya AI mobile app.",
      images: [{ img: upload_2 }],
      tooltip_text: "Handwritten Answer Sheets",
    },
    {
      id: 2,
      icon: <Icons.RoundCheck size={24} className="icon" />,
      title: "3. Analyze",
      text: "AI automatically grades papers based on model answers and analyzes student performance.",
      images: [{ img: analyze }],
      tooltip_text: "Chankaya AI Analysis Platform",
    },
  ];

  const featuresData = [
    {
      id: 0,
      title: "Automate Grading with AI",
      list: [
        { text: "Get results in less than 48 hours" },
        { text: "Consistent and fair evaluation every time" },
        // { text: "Save money on grading tasks" },
        { text: "Detailed explanation for every mark awarded or deducted" },
        { text: "Instantly recheck and verify marks" },
      ],
    },
    {
      id: 1,
      title: "SWOT Analysis & Reports",
      list: [
        { text: "Personalized feedback for every chapter" },
        { text: "Clear insights into strengths and areas to improve" },
        { text: "Track progress throughout the year" },
        { text: "Spot common mistakes and get tips to improve scores" },
      ],
    },
    {
      id: 2,
      title: "Smarter Insights",
      list: [
        { text: "Know exactly what to revise in the next class" },
        { text: "Analyze performance for batches and individual teachers" },
        { text: "Use data-backed suggestions for customized teaching strategies" },
      ],
    },
  ];  
  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <EduHeader />
      <main className="education-page-main">
        <section className="banner-section">
          <div className="container">
            <div className="banner-wrapper">
              <div>
                <h1 className="banner-title">
                  AI
                  <span className="orange-text caveat-text">
                    Paper Grading
                  </span>{" "}
                  and <span className="orange-text caveat-text">Analysis</span>
                </h1>
                <p className="banner-text">
                  Speed up assessments and identify student strengths and
                  weaknesses.
                </p>

                <CustomLink
                  linkText="Schedule a Call with Founders"
                  LinkTo="https://calendly.com/atharv-gyem/discovery-call"
                  onClick={() =>
                    logEvent(
                      "CTA",
                      "Click",
                      "Header - Schedule a Call with Founders"
                    )
                  }
                />
              </div>
              {/* <div className="banner-right">
                <div className="banner-card p-8 shadow-xl">
                  <div className="space-y-4">
                    <h3 className="card-title">Current Challenges</h3>
                    <ul className="card-list">
                      <li className="card-list-item">
                        <Icons.Clock size={24} className="icon" /> 2 weeks
                        average turnaround time
                      </li>
                      <li className="card-list-item">
                        <Icons.ArrowRight size={24} className="icon" />
                        Inconsistent paper checking
                      </li>
                      <li className="card-list-item">
                        <Icons.ArrowRight size={24} className="icon" />
                        High administrative overhead
                      </li>
                      <li className="card-list-item">
                        <Icons.ArrowRight size={24} className="icon" />
                        20-25 Rs cost per paper
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="video-wrapper">
              <video
                className="video"
                id="my-video"
                width="1200"
                height="500"
                autoPlay
                muted
                loop
                playsInline
                ref={videoRef}
              >
                <source
                  src="https://objectstore.e2enetworks.net/neurobridge-public/edu/demo.mp4"
                  type="video/mp4"
                />
              </video>
              <button onClick={handlePlayPause} className="videoControl">
                {isPlaying ? (
                  <span>
                    <Icons.Pause />
                    Pause
                  </span>
                ) : (
                  <span>
                    <Icons.Play />
                    Play
                  </span>
                )}
              </button>
              <button onClick={toggleMute} className="muteBtn">
                {isMuted ? (
                  <Icons.Mute size={25} fill="#ffffff" />
                ) : (
                  <Icons.Volume size={25} fill="#ffffff" />
                )}
              </button>
            </div>
          </div>
        </section>
        <section className="feature-section">
          <div className="container">
            <h3 className="section-title">Features</h3>
            <div className="feature-card-wrapper">
              {featuresData?.map((items, index) => (
                <div key={index} className="features-card p-6">
                  <div className="space-y-4">
                    <h4 className="feature-card-title">{items.title}</h4>
                    <ul className="feature-list">
                      {items?.list?.map((item, index) => (
                        <li key={index} className="features-card-text">
                          <Icons.RoundCheck size={15} className="icon" />
                          {item.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="how-it-works">
          <div className="container">
            <h3 className="section-title">How it Works</h3>
            <div className="works-card-main">
              {howItWorksData?.map((items, index) => (
                <div key={index} className="works-cards-wrapper">
                  <div className="wrapper-step">
                    <span>{items.id + 1}</span>
                  </div>
                  <div className="content-wrapper">
                    <h3 className="work-title">
                      {items.title}
                      <div className="work-card-icon">{items.icon}</div>
                    </h3>
                    <p className="work-text">{items.text}</p>
                  </div>
                  <div className="img-main">
                    {items?.images?.map((item, index) => (
                      <img
                        loading="lazy"
                        key={index}
                        src={item.img}
                        className="img_1"
                        alt="side-image"
                        title={items.tooltip_text}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="powerUp-section">
          <div className="container">
            <div className="tag-main">
              <h3 className="section-tag">Power Up Your Learning</h3>
            </div>
            <p className="powerUp-text">
              Give your students quicker, better, more insightful feedback on
              their tests
            </p>
            <div className="accessBox-main">
              <CustomLink
                linkText="Schedule a Call with Founders"
                customClasses="access-btn"
                LinkTo="https://calendly.com/atharv-gyem/discovery-call"
                onClick={() =>
                  logEvent(
                    "CTA",
                    "Click",
                    "Header - Schedule a Call with Founders"
                  )
                }
              />
            </div>
          </div>
        </section>
      </main>
      <OuterFooter />
    </>
  );
};

export default EducationPage;
