import React, { useEffect } from "react";
import AOS from "aos";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import UseLenis from "./components/useLenis";
import { ParallaxProvider } from "react-scroll-parallax";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import { initGA } from "./components/Analytics";
import EducationPage from "./pages/EducationPage";
import AboutPage from "./pages/About";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-out-cubic",
    });
  }, []);
  useEffect(() => {
    initGA();
  });
  return (
    <ParallaxProvider>
      <BrowserRouter>
        <UseLenis>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/edu" element={<EducationPage />} />
            <Route path="/about-us" element={<AboutPage />} />
          </Routes>
        </UseLenis>
      </BrowserRouter>
    </ParallaxProvider>
  );
};

export default App;
