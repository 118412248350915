import React, { useRef } from "react";
import "./style.css";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { Icons } from "../icons";

const LeftDrawer = ({ showDrawer, setShowDrawer, directorData }) => {
  const sectionRef = useRef();
  useGSAP(() => {
    gsap.from(sectionRef.current, {
      opacity: 0,
      duration: 0.3,
    });
  });

  return (
    <div className={`drawer ${showDrawer ? "show" : ""}`}>
      <div
        className="drawer-backdrop"
        onClick={() => setShowDrawer(false)}
      ></div>

      <div className="drawer-content" ref={sectionRef}>
        <div className="drawer-header">
          <h4 className="drawer-title">About</h4>
          <Icons.Close
            size={26}
            fill="#1f1f1f"
            className="close"
            onClick={() => setShowDrawer(false)}
          />
        </div>
        <div className="drawer-body">
          <div className="modal-info">
            <div className="img-container">
              <img src={directorData.img} alt="director" />
            </div>
            <h4 className="body-title">{directorData.name}</h4>
            <h5 className="body-subtitle">{directorData.designation}</h5>
          </div>
          <div className="info-sec">
            <p className="body-text">{directorData.about}</p>
            <a href="mailto:directorData.email" className="mail">
              <Icons.Mail size={24} width={30} fill="#ff4d00" />
              {directorData.email}
            </a>
            <div className="social-section">
              <h5 className="social-title">Lets Connect</h5>
              <a href={directorData.linkedin} className="social">
                <Icons.Linkedin size={26} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftDrawer;
