export const Icons = {
  Clock: ({ size, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <polyline points="12 6 12 12 16 14"></polyline>
    </svg>
  ),
  ArrowRight: ({ size, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M5 12h14"></path>
      <path d="m12 5 7 7-7 7"></path>
    </svg>
  ),
  BookOpen: ({ size, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
      <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
    </svg>
  ),
  Upload: ({ size, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="17 8 12 3 7 8"></polyline>
      <line x1="12" y1="3" x2="12" y2="15"></line>
    </svg>
  ),
  RoundCheck: ({ size, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
      <path d="m9 11 3 3L22 4"></path>
    </svg>
  ),
  Play: ({ size, fill, ...props }) => (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill={fill}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.64711e-08 0.66661V11.3333C-3.3396e-05 11.4519 0.0315832 11.5684 0.0915877 11.6707C0.151592 11.773 0.237811 11.8575 0.34135 11.9154C0.444888 11.9733 0.561996 12.0025 0.68059 12C0.799184 11.9975 0.914969 11.9634 1.016 11.9013L9.68267 6.56794C9.77973 6.5083 9.8599 6.42477 9.9155 6.32534C9.97111 6.2259 10.0003 6.11387 10.0003 5.99994C10.0003 5.88602 9.97111 5.77399 9.9155 5.67455C9.8599 5.57512 9.77973 5.49159 9.68267 5.43194L1.016 0.0986104C0.914969 0.0364535 0.799184 0.00237861 0.68059 -9.87032e-05C0.561996 -0.00257601 0.444888 0.026634 0.34135 0.0845176C0.237811 0.142401 0.151592 0.226862 0.0915877 0.329186C0.0315832 0.43151 -3.3396e-05 0.54799 2.64711e-08 0.66661Z"
        fill="white"
      />
    </svg>
  ),
  Pause: ({ size, fill, ...props }) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5517_4221)">
        <path
          d="M5.99992 2.66675H4.66659C4.31296 2.66675 3.97382 2.80722 3.72378 3.05727C3.47373 3.30732 3.33325 3.64646 3.33325 4.00008V12.0001C3.33325 12.3537 3.47373 12.6928 3.72378 12.9429C3.97382 13.1929 4.31296 13.3334 4.66659 13.3334H5.99992C6.35354 13.3334 6.69268 13.1929 6.94273 12.9429C7.19278 12.6928 7.33325 12.3537 7.33325 12.0001V4.00008C7.33325 3.64646 7.19278 3.30732 6.94273 3.05727C6.69268 2.80722 6.35354 2.66675 5.99992 2.66675Z"
          fill="white"
        />
        <path
          d="M11.3334 2.66675H10.0001C9.64646 2.66675 9.30732 2.80722 9.05727 3.05727C8.80722 3.30732 8.66675 3.64646 8.66675 4.00008V12.0001C8.66675 12.3537 8.80722 12.6928 9.05727 12.9429C9.30732 13.1929 9.64646 13.3334 10.0001 13.3334H11.3334C11.687 13.3334 12.0262 13.1929 12.2762 12.9429C12.5263 12.6928 12.6667 12.3537 12.6667 12.0001V4.00008C12.6667 3.64646 12.5263 3.30732 12.2762 3.05727C12.0262 2.80722 11.687 2.66675 11.3334 2.66675Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5517_4221">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  ArrowRight2: ({ size, fill, ...props }) => (
    <svg
      width={size}
      height={size}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      {...props}
    >
      {/* Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc. */}
      <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
    </svg>
  ),
  Linkedin: ({ size, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_146_986)">
        <rect width="34" height="34" rx="4" fill="white" />
        <path
          d="M31.4901 0H2.50986C1.8442 0 1.20581 0.264431 0.735122 0.735122C0.264431 1.20581 0 1.8442 0 2.50986V31.4901C0 32.1558 0.264431 32.7942 0.735122 33.2649C1.20581 33.7356 1.8442 34 2.50986 34H31.4901C32.1558 34 32.7942 33.7356 33.2649 33.2649C33.7356 32.7942 34 32.1558 34 31.4901V2.50986C34 1.8442 33.7356 1.20581 33.2649 0.735122C32.7942 0.264431 32.1558 0 31.4901 0ZM10.1339 28.9637H5.02208V12.7264H10.1339V28.9637ZM7.57445 10.4763C6.9946 10.473 6.42871 10.298 5.94821 9.97346C5.4677 9.64889 5.09411 9.18925 4.87458 8.65256C4.65505 8.11587 4.59942 7.52617 4.71471 6.95789C4.83 6.38962 5.11105 5.86823 5.52239 5.45952C5.93372 5.05082 6.4569 4.77313 7.02591 4.66149C7.59492 4.54984 8.18424 4.60926 8.71951 4.83223C9.25479 5.0552 9.71201 5.43174 10.0335 5.91432C10.355 6.3969 10.5263 6.9639 10.5258 7.54375C10.5313 7.93196 10.4586 8.31731 10.3119 8.67681C10.1653 9.03631 9.94781 9.36261 9.67239 9.63626C9.39698 9.90991 9.06929 10.1253 8.70885 10.2696C8.34842 10.4139 7.96262 10.4842 7.57445 10.4763ZM28.9756 28.9779H23.8661V20.1072C23.8661 17.4911 22.754 16.6836 21.3185 16.6836C19.8026 16.6836 18.3151 17.8264 18.3151 20.1733V28.9779H13.2033V12.7382H18.1192V14.9883H18.1853C18.6788 13.9896 20.4071 12.2825 23.0444 12.2825C25.8967 12.2825 28.9779 13.9754 28.9779 18.9338L28.9756 28.9779Z"
          fill="url(#paint0_linear_146_986)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_146_986"
          x1="0.396667"
          y1="10.54"
          x2="18.2347"
          y2="19.9907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E8AF2" />
          <stop offset="1" stopColor="#05BED7" />
        </linearGradient>
        <clipPath id="clip0_146_986">
          <rect width="34" height="34" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  Close: ({ size, fill, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={fill}
      viewBox="0 0 384 512"
      {...props}
    >
      {/* Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc. */}
      <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
    </svg>
  ),
  Mail: ({ size, width, fill, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={size}
      fill={fill}
      viewBox="0 0 384 512"
      {...props}
    >
      {/* Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc. */}
      <path d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z" />
    </svg>
  ),
  Pen: ({ size, width, fill, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={size}
      fill={fill}
    >
      <path d="M368.4 18.3L312.7 74.1 437.9 199.3l55.7-55.7c21.9-21.9 21.9-57.3 0-79.2L447.6 18.3c-21.9-21.9-57.3-21.9-79.2 0zM288 94.6l-9.2 2.8L134.7 140.6c-19.9 6-35.7 21.2-42.3 41L3.8 445.8c-3.8 11.3-1 23.9 7.3 32.4L164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L33.7 500.9c8.6 8.3 21.1 11.2 32.4 7.3l264.3-88.6c19.7-6.6 35-22.4 41-42.3l43.2-144.1 2.7-9.2L288 94.6z" />
    </svg>
  ),
  Chart: ({ size, width, fill, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={size}
      fill={fill}
    >
      <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64L0 400c0 44.2 35.8 80 80 80l400 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 416c-8.8 0-16-7.2-16-16L64 64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
    </svg>
  ),
  Network: ({ size, width, fill, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width={width}
      height={size}
      fill={fill}
    >
      <path d="M256 64l128 0 0 64-128 0 0-64zM240 0c-26.5 0-48 21.5-48 48l0 96c0 26.5 21.5 48 48 48l48 0 0 32L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0 0 32-48 0c-26.5 0-48 21.5-48 48l0 96c0 26.5 21.5 48 48 48l160 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-48 0 0-32 256 0 0 32-48 0c-26.5 0-48 21.5-48 48l0 96c0 26.5 21.5 48 48 48l160 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-48 0 0-32 96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-256 0 0-32 48 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48L240 0zM96 448l0-64 128 0 0 64L96 448zm320-64l128 0 0 64-128 0 0-64z" />
    </svg>
  ),
  Mute: ({ size, width, fill, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width={width}
      height={size}
      fill={fill}
    >
      <path d="M301.1 34.8C312.6 40 320 51.4 320 64l0 384c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352 64 352c-35.3 0-64-28.7-64-64l0-64c0-35.3 28.7-64 64-64l67.8 0L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z" />
    </svg>
  ),
  Volume: ({ size, width, fill, ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      width={width}
      height={size}
      fill={fill}
    >
      <path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64l0 384c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352 64 352c-35.3 0-64-28.7-64-64l0-64c0-35.3 28.7-64 64-64l67.8 0L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z" />
    </svg>
  ),
};
