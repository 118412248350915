import React, { useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import DownArrow from "../../assets/img/down_arrow.svg";
import DoubleUpArrow from "../../assets/img/CaretDoubleUp.svg";
import ChanakyaLogo from "../../assets/img/Chanakya_Logo.svg";

const Header = ({
  scrollToSection,
  mobNav,
  setMobNav,
  setMobNavbar,
  mobNavbar,
  handleDownloadAppButtonClick,
}) => {
  const location = useLocation();
  const [deviceType, setDeviceType] = useState("");

  const handleMobileNav = () => {
    if (location?.pathname === "/privacy" || location?.pathname === "/tos") {
      setMobNavbar(!mobNavbar);
    } else {
      setMobNav(!mobNav);
    }
  };

  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    if (/window phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/Win/i.test(userAgent)) {
      return "Windows";
    }

    return "unknown";
  };

  useEffect(() => {
    const device = getDeviceType();
    setDeviceType(device);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <header className="header">
        <nav className="nav navbar-nav">
          <div className="header-logo">
            <Link to="/" className="logo" onClick={scrollToTop}>
              <img src={ChanakyaLogo} alt="" />
            </Link>
          </div>
          <div className="navbar-links">
            <ul className="nav-list">
              {location?.pathname === "/privacy" ||
              location?.pathname === "/tos" ? (
                ""
              ) : (
                <>
                  <li className="nav-items">
                    <Link to="/about-us" className="nav-link">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-items">
                    <button
                      onClick={() => scrollToSection("explore")}
                      className="nav-link"
                    >
                      Explore AI
                    </button>
                  </li>
                  <li className="nav-items">
                    <button
                      onClick={() => scrollToSection("pricing")}
                      className="nav-link"
                    >
                      Pricing
                    </button>
                  </li>
                  <li className="nav-items">
                    <button
                      onClick={() => scrollToSection("faq")}
                      className="nav-link"
                    >
                      FAQ'S
                    </button>
                  </li>
                  <li>
                    <Link to="/edu" className="nav-link">
                      Edu
                    </Link>
                  </li>
                  <li className="nav-items">
                    <Link to="https://chat.aichanakya.in/" className="nav-link">
                      Sign in
                    </Link>
                  </li>
                </>
              )}
              <li className="nav-items">
                <a
                  href={
                    deviceType === "Windows"
                      ? "https://chat.aichanakya.in/"
                      : deviceType === "Android"
                      ? "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya"
                      : deviceType === "iOS"
                      ? "https://apps.apple.com/us/app/chanakya-ai/id6504797681"
                      : "/"
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="btn download-btn"
                  onClick={handleDownloadAppButtonClick}
                >
                  Download App
                </a>
              </li>
            </ul>
            {mobNav ? (
              <button className="close-nav" onClick={handleMobileNav}>
                <img src={DoubleUpArrow} alt="icon" />
              </button>
            ) : (
              <button className="toggle-btn" onClick={handleMobileNav}>
                <span></span>
              </button>
            )}
          </div>
        </nav>
      </header>
      <div
        className={`mobileNav-wrapper ${mobNav || mobNavbar ? "active" : ""}`}
      >
        <ul className="mobileNav-list">
          {location?.pathname === "/privacy" ||
          location?.pathname === "/tos" ? (
            ""
          ) : (
            <>
              <li className="mobileNav-item">
                <Link to="about-us" className="mob-nav-btn">
                  About Us
                  <img src={DownArrow} alt="icon" />
                </Link>
              </li>
              <li className="mobileNav-item">
                <button
                  type="button"
                  className="mob-nav-btn"
                  onClick={() => scrollToSection("explore", "native")}
                >
                  Explore AI
                  <img src={DownArrow} alt="icon" />
                </button>
              </li>
              <li className="mobileNav-item">
                <button
                  type="button"
                  className="mob-nav-btn"
                  onClick={() => scrollToSection("pricing", "native")}
                >
                  Pricing
                  <img src={DownArrow} alt="icon" />
                </button>
              </li>
              <li className="mobileNav-item">
                <button
                  type="button"
                  className="mob-nav-btn"
                  onClick={() => scrollToSection("faq", "native")}
                >
                  FAQs
                  <img src={DownArrow} alt="icon" />
                </button>
              </li>
              <li>
                <Link to="/edu" className="mob-nav-btn">
                  Edu
                </Link>
              </li>
              <li className="mobileNav-item">
                <a
                  href="https://chat.aichanakya.in/"
                  className="mob-nav-btn"
                  // onClick={handleMobileNav}
                >
                  Sign In
                  <img src={DownArrow} alt="icon" />
                </a>
              </li>
            </>
          )}
          <li className="mobileNav-item">
            <a
              href={
                deviceType === "Windows"
                  ? "https://chat.aichanakya.in/"
                  : deviceType === "Android"
                  ? "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya"
                  : deviceType === "iOS"
                  ? "https://apps.apple.com/us/app/chanakya-ai/id6504797681"
                  : "/"
              }
              target="_blank"
              rel="noreferrer"
              className="mob-download-btn"
              onClick={handleDownloadAppButtonClick}
            >
              Download App
            </a>
          </li>
        </ul>
      </div>
      <div
        className={`navback-drop ${mobNav || mobNavbar ? "show" : ""}`}
        onClick={handleMobileNav}
      ></div>
    </>
  );
};

export default Header;
