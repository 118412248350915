import React, { useState } from "react";
import "./style.css";
import ChanakyaLogo from "../../assets/img/Chanakya_Logo.svg";
import { Link } from "react-router-dom";
import DoubleUpArrow from "../../assets/img/CaretDoubleUp.svg";
import DownArrow from "../../assets/img/down_arrow.svg";

const EduHeader = () => {
  const [mobNav, setMobNav] = useState(false);
  const handleMobileNav = () => {
    setMobNav(!mobNav);
  };

  return (
    <>
      <header className="header">
        <nav className="nav navbar-nav">
          <div className="header-logo">
            <Link to="/" className="logo">
              <img src={ChanakyaLogo} alt="" />
            </Link>
          </div>
          <div className="navbar-links">
            <ul className="nav-list">
              <li className="nav-items">
                <Link to="/about-us" className="nav-link">
                  About Us
                </Link>
              </li>
              <li className="nav-items">
                <Link
                  to="https://calendly.com/atharv-gyem/discovery-call"
                  rel="noreferrer"
                  className="btn download-btn"
                >
                  Get Early Access
                </Link>
              </li>
            </ul>
            {mobNav ? (
              <button className="close-nav" onClick={handleMobileNav}>
                <img src={DoubleUpArrow} alt="icon" />
              </button>
            ) : (
              <button className="toggle-btn" onClick={handleMobileNav}>
                <span></span>
              </button>
            )}
          </div>
        </nav>
      </header>
      <div className={`mobileNav-wrapper ${mobNav ? "active" : ""}`}>
        <ul className="mobileNav-list">
          <li className="mobileNav-item">
            <Link to="/about-us" className="mob-nav-btn">
              About Us
              <img src={DownArrow} alt="icon" />
            </Link>
          </li>
          <li className="mobileNav-item">
            <Link
              to="https://calendly.com/atharv-gyem/discovery-call"
              target="_blank"
              rel="noreferrer"
              className="mob-download-btn"
            >
              Get Early Access
            </Link>
          </li>
        </ul>
      </div>
      <div
        className={`navback-drop ${mobNav ? "show" : ""}`}
        onClick={handleMobileNav}
      ></div>
    </>
  );
};

export default EduHeader;
